/**
 * Created by user on 11/3/2015.
 */

// Namespace
var __thisPage = {};

/* Add CSRF Token for every ajax request */
$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    jqXHR.setRequestHeader('X-CSRF-Token', _token);
});


$(function () {
    $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue'
    });

    $('form.animate-submit').submit(function (e) {
        var leButton = $(this).find('[type=submit]');
        if (!$(leButton).data('loading-text')) {
            $(leButton).data('loading-text', "<i class='fa fa-refresh fa-spin'></i> " + TRANS['processing'] + "..")
        }
        var btn = $(leButton).button();
        btn.button('loading');
    });
    $('form.no-submit').submit(function(e){
        e.preventDefault();
        return false;
    });

    initDeleteButtons();
    initRestoreButtons();
    initArchiveButtons();
    initReactivateButtons();

    $('body').on('click', '.btnCloseMagnific', function(e){
        e.preventDefault();
        $.magnificPopup.close();
    });

    initAjaxMagnific();
    initEqualizer();
    initStickyTabs();
    initTextareaAutoSize();
    initHighlightOnClick();
});