/**
 * Created by user on 29/4/2015.
 */
function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}

function makeTextEditor(options){
    if(!options) options = {};
    $.extend(true, options, tinyMCEDefaultOptions);
    tinymce.init(options);
}

function closeMagnific() {
    $.magnificPopup.close();
}

function isValidEmailAddress(emailAddress) {
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
    return pattern.test(emailAddress);
}
function replaceRoute(route, entity, value) {
    route = route.replace('_', '-');
    route = route.replace('%7B' + entity + '%7D', value);
    return route;
}
function select2GroupMatcher(params, data) {
    data.parentText = data.parentText || "";

    // Always return the object if there is nothing to compare
    if ($.trim(params.term) === '') {
        return data;
    }

    // Do a recursive check for options with children
    if (data.children && data.children.length > 0) {
        // Clone the data object if there are children
        // This is required as we modify the object to remove any non-matches
        var match = $.extend(true, {}, data);

        // Check each child of the option
        for (var c = data.children.length - 1; c >= 0; c--) {
            var child = data.children[c];
            child.parentText += data.parentText + " " + data.text;

            var matches = select2GroupMatcher(params, child);

            // If there wasn't a match, remove the object in the array
            if (matches == null) {
                match.children.splice(c, 1);
            }
        }

        // If any children matched, return the new object
        if (match.children.length > 0) {
            return match;
        }

        // If there were no matching children, check just the plain object
        return select2GroupMatcher(params, match);
    }

    // If the typed-in term matches the text of this term, or the text from any
    // parent term, then it's a match.
    var original = (data.parentText + ' ' + data.text).toUpperCase();
    var term = params.term.toUpperCase();


    // Check if the text contains the term
    if (original.indexOf(term) > -1) {
        return data;
    }

    // If it doesn't contain the term, don't return anything
    return null;
}

function addFormFieldMessage(fieldName, msg, type, context){
    if(!type){
        type = 'error';
    }
    var $field;
    if(!context) {
        $field = $('[name="' + fieldName + '"]');
    } else {
        $field = context.find('[name="' + fieldName + '"]');
    }
    if(type == 'error'){
        $field.closest('.form-group').addClass('has-error');
    }

    var $helpBlock;
    if ($field.parent().hasClass("input-group")) {
        if (!$field.parent().siblings('.help-block').length) {
            $field.parent().after('<span class="help-block"</span>');
        }
        $helpBlock = $field.parent().siblings('.help-block');
    }else{
        if (!$field.siblings('.help-block').length) {
            $field.after('<span class="help-block"</span>');
        }
        $helpBlock = $field.siblings('.help-block');
    }
    $helpBlock.text(msg);
}

function addErrorsToForm(o, context){
    $.each(o, function(fieldName, e){
        $.each(e, function(i2, e2){
            addFormFieldMessage(fieldName, e2, 'error', context);
        });
    });
}

function makeMagnificAjax($element){
    $element.magnificPopup({
        type: 'ajax',
        mainClass: 'mfp-fade',
        removalDelay: 300,
        gallery: {
            enabled: true
        },
        callbacks: {
            beforeOpen: function () {
                $(this.st.el).blur();
            }
        }
    });
}