function initDeleteButtons() {
    $('body').on('click', '[data-delete]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var name = $(this).data('name');
        var entity = $(this).data('entity');
        var url = $(this).data('delete');
        var callback = $(this).data('callback');

        if (!$(this).data('loading-text')) {
            $(this).data('loading-text', "<i class='fa fa-refresh fa-spin'></i>")
        }
        var btn = $(this).button();
        btn.button('loading');

        bootbox.confirm({
            title: TRANS['delete'] + ' ' + entity + ' [' + name + ']?',
            message: TRANS['confirm_delete_message'] + ' ' + entity + '?',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: url,
                        type: 'post',
                        data: {
                            _token: $('#_token').text(),
                            _method: 'DELETE'
                        },
                        success: function (result) {
                            if (result.status == 1) {
                                if (callback) {
                                    window['__thisPage'][callback]();
                                } else {
                                    window.location.reload();
                                }
                            }
                            else if (result.status == 0)
                                bootbox.alert(result.message);
                            else
                                bootbox.alert(TRANS['error_occured']);
                        }
                    }).always(function () {
                        btn.button('reset');
                    });
                } else {
                    btn.button('reset');
                }
            }
        });
    });
}


function initRestoreButtons() {
    $('body').on('click', '[data-restore]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var name = $(this).data('name');
        var entity = $(this).data('entity');
        var url = $(this).data('restore');

        if (!$(this).data('loading-text')) {
            $(this).data('loading-text', "<i class='fa fa-refresh fa-spin'></i>")
        }
        var btn = $(this).button();
        btn.button('loading');

        bootbox.confirm({
            title: TRANS['restore'] + ' ' + entity + ' [' + name + ']?',
            message: TRANS['confirm_restore_message'] + ' ' + entity + '?',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: url,
                        type: 'post',
                        data: {
                            _token: $('#_token').text()
                        },
                        success: function (result) {
                            if (result.status == 1)
                                window.location.reload();
                            else
                                bootbox.alert(TRANS['error_occured']);
                        },
                        always: function () {
                            btn.button('reset');
                        }
                    });
                } else {
                    btn.button('reset');
                }
            }
        });
    });
}

function initArchiveButtons() {
    $('body').on('click', '[data-archive]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var name = $(this).data('name');
        var entity = $(this).data('entity');
        var url = $(this).data('archive');

        if (!$(this).data('loading-text')) {
            $(this).data('loading-text', "<i class='fa fa-refresh fa-spin'></i>")
        }
        var btn = $(this).button();
        btn.button('loading');

        bootbox.confirm({
            title: TRANS['archive'] + ' ' + entity + ' [' + name + ']?',
            message: TRANS['confirm_archive_message'] + ' ' + entity + '?',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: url,
                        type: 'post',
                        data: {
                            _token: $('#_token').text()
                        },
                        success: function (result) {
                            if (result.status == 1)
                                window.location.reload();
                            else
                                bootbox.alert(TRANS['error_occured']);
                        },
                        always: function () {
                            btn.button('reset');
                        }
                    });
                } else {
                    btn.button('reset');
                }
            }
        });
    });
}

function initReactivateButtons() {
    $('body').on('click', '[data-reactivate]', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var name = $(this).data('name');
        var entity = $(this).data('entity');
        var url = $(this).data('reactivate');

        if (!$(this).data('loading-text')) {
            $(this).data('loading-text', "<i class='fa fa-refresh fa-spin'></i>")
        }
        var btn = $(this).button();
        btn.button('loading');

        bootbox.confirm({
            title: TRANS['reactivate'] + ' ' + entity + ' [' + name + ']?',
            message: TRANS['confirm_reactivate_message'] + ' ' + entity + '?',
            callback: function (result) {
                if (result) {
                    $.ajax({
                        url: url,
                        type: 'post',
                        data: {
                            _token: $('#_token').text()
                        },
                        success: function (result) {
                            if (result.status == 1)
                                window.location.reload();
                            else
                                bootbox.alert(TRANS['error_occured']);
                        },
                        always: function () {
                            btn.button('reset');
                        }
                    });
                } else {
                    btn.button('reset');
                }
            }
        });
    });
}

function initEqualizer() {
    var initialized = [];
    $('[data-equalize-height]').each(function () {
        var eName = $(this).data('equalize-height');
        if (!$.inArray(eName, initialized)) {
            $('[data-equalize-height="' + eName + '"]').equalize();
            initialized.push(eName);
        }
    });
}

function initAjaxDataTable($selector, url, options) {

    var $ajaxDataTable = $selector;
    var columns = (typeof options.columns !== 'undefined') ? options.columns : false;
    var extraData = (typeof options.extraData !== 'undefined') ? options.extraData : {};
    var dom = (typeof options.dom !== 'undefined') ? options.dom :
    "<'row'<'col-sm-6'f><'col-sm-6'l>>" +
    "<'row'<'col-sm-12'tr>>" +
    "<'row'<'col-sm-6'i><'col-sm-6'p>>";

    if (!$.fn.DataTable.isDataTable($selector)) {
        if (!columns) {
            columns = [];
            $selector.find("thead th").each(function () {
                if ($(this).hasClass('control')) {
                    columns.push({
                        data: null,
                        createdCell: function (td, cellData, rowData, row, col) {
                            $(td).addClass('control');
                        },
                        render: function () {
                            return '';
                        }
                    });
                } else if ($(this).data("source")) {
                    var column = {};
                    column.data = $(this).data("source") || null;
                    if ($(this).data("render")) {
                        column.render = window['__thisPage'][$(this).data("render")];
                    }
                    if ($(this).data("created")) {
                        column.createdCell = window['__thisPage'][$(this).data("created")];
                    }
                    if ($(this).data("source") == 'sort_order') {
                        column.createdCell = function (td) {
                            $(td).addClass('text-center');
                        }
                    }
                    if ($(this).hasClass("text-center")) {
                        column.createdCell = function (td) {
                            $(td).addClass('text-center');
                        }
                    }
                    columns.push(column);
                } else {
                    columns.push({data: null});
                }
            });
        }

        var hasSortOrderable = false;
        var sortOrderable = false;
        if ($selector.hasClass('sortOrderable')) {
            hasSortOrderable = true;
            sortOrderable = true;
            dom = "<'row'<'col-sm-6'f><'col-sm-6'<'sortOrderButtonContainer'>l>><'row'<'col-sm-12'tr>><'row'<'col-sm-6'i><'col-sm-6'p>>";
        }

        $ajaxDataTable = $selector.DataTable($.extend({}, datatablesData, {
            "processing": true,
            "serverSide": true,
            "ajax": {
                url: url,
                data: extraData
            },
            "columns": columns,
            dom: dom
        }));

        if (hasSortOrderable) {
            $selector.closest('.dataTables_wrapper').find('.sortOrderButtonContainer').each(function () {
                $(this).html('<a class="btn btn-warning btn-flat btn-sm pull-right btnInitSort" data-mfp-src="#divSorts"><i class="fa fa-sort"></i> Sort Order</a>');
            });

            $('#divSorts .olSortable').sortable();

            $('.btnInitSort').magnificPopup({
                type: 'inline',
                midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
                mainClass: 'mfp-fade'
            });
        }

        $ajaxDataTable.on('draw', function () {
            var body = $($ajaxDataTable.table().body());

            body.unhighlight();
            body.highlight($ajaxDataTable.search());

            if (options.callback) {
                options.callback($(this));
            }
        });

        var $searchInput = $selector.closest('.dataTables_wrapper').find('div.dataTables_filter input');
        $searchInput.unbind();
        $searchInput.bind('keyup', function (e) {
            if (e.keyCode == 13) {
                $ajaxDataTable.search(this.value).draw();
            }
        });

        return $ajaxDataTable;
    } else {
        $ajaxDataTable.ajax.reload();
    }
}

function initAjaxMagnific() {
    var options = {
        type: 'ajax',
        gallery: {
            enabled: true
        },
        mainClass: 'mfp-fade',
        removalDelay: 300,
        callbacks: {
            beforeOpen: function(){
                $(this.st.el).blur();
            }
        }
    };
    $('[data-magnific-ajax="package"]').magnificPopup(options);
    $('[data-magnific-ajax="course"]').magnificPopup(options);
    $('[data-magnific-ajax="application"]').magnificPopup(options);
    $('[data-magnific-ajax="bank-detail"]').magnificPopup(options);
    $('[data-magnific-ajax="offer-letter"]').magnificPopup(options);
    $('[data-magnific-ajax="promo-code"]').magnificPopup(options);
    $('[data-magnific-ajax="collaboration"]').magnificPopup(options);
    $('[data-magnific-ajax="enquiry"]').magnificPopup(options);
    $('[data-magnific-ajax="invoice"]').magnificPopup(options);
    $('[data-magnific-ajax="receipt"]').magnificPopup(options);

    $('[data-magnific="ajax"]').magnificPopup({
        type: 'ajax',
        mainClass: 'mfp-fade',
        removalDelay: 300
    });

    $('[data-magnific="image"]').magnificPopup({
        type: 'image',
        mainClass: 'mfp-fade',
        removalDelay: 300,
        image: {
            titleSrc: 'data-title'
        }
    });
}

function initStickyTabs() {
    $('.sticky-tabs').each(function () {
        var $tabs = $(this);
        var hash = window.location.hash;
        if (!hash || $(hash).length <= 1) {
            $tabs.find('li>a').first().click();
        } else {
            if ($(this).find(hash).length <= 1) {
                $tabs.find('li>a').first().click();
            }
        }
        $tabs.stickyTabs();
    });
}

function initTextareaAutoSize() {
    autosize($('textarea.autosize'));
}

function initHighlightOnClick() {
    $('body').on('focus', '.highlight-on-click', function () {
        $(this).select();
    })
}